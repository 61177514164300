import { OnboardingType } from '@kvika/api-types';
import { NavigationProps } from '../types/Types';
import {
  GeneralBankingProgressSteps,
  CapitalMarketsProgressSteps,
  PrivateBankingProgressSteps,
  DerivativesProgressSteps,
  KardioProgressSteps,
} from '../constants/ProgressStepsConstants';
import { GeneralBankingNavigationTree } from '../constants/GeneralBankingNavigationTree';
import { CapitalMarketsNavigationTree } from '../constants/CapitalMarketsNavigationTree';
import { CommonNavigationTree } from '../constants/CommonNavigationTree';
import { NavigationConstants } from '../constants/NavigationConstants';
import { PrivateBankingNavigationTree } from '../constants/PrivateBankingNavigationTree';
import { DerivativesNavigationTree } from '../constants/DerivativesNavigationTree';
import { getIsDependantOnCapitalMarkets } from './Utils';
import { KardioNavigationTree } from '../constants/KardioNavigationTree';

const getNavigationTree = (currentPage: string, onboardingFlow?: OnboardingType) => {
  if (onboardingFlow === OnboardingType.KARDIO) {
    return KardioNavigationTree;
  }

  if (CommonNavigationTree[currentPage]) {
    return CommonNavigationTree;
  }

  if (onboardingFlow === OnboardingType.GENERAL_BANKING) {
    return GeneralBankingNavigationTree;
  }
  if (onboardingFlow === OnboardingType.CAPITAL_MARKETS) {
    return CapitalMarketsNavigationTree;
  }
  if (onboardingFlow === OnboardingType.PRIVATE_BANKING) {
    return PrivateBankingNavigationTree;
  }
  if (onboardingFlow === OnboardingType.DERIVATIVES) {
    return DerivativesNavigationTree;
  }
  if (onboardingFlow === OnboardingType.KARDIO) {
    return KardioNavigationTree;
  }

  return undefined;
};

type GetNextPageProps = {
  onboardingFlow?: OnboardingType;
  selectedFlows?: OnboardingType[];
  currentPage: string;
  props: NavigationProps;
};

export const EndPages = [
  NavigationConstants.UNDIRRITUN_PEP,
  NavigationConstants.UNDIRRITUN,
  NavigationConstants.UNDIRRITUN_GB,
  NavigationConstants.UNDIRRITUN_GB_LOGADILI,
  NavigationConstants.VELDU_PIN,
  NavigationConstants.UNDIRRITUN_LOGADILI,
];

export const getNextPage = ({ onboardingFlow, selectedFlows = [], currentPage, props }: GetNextPageProps) => {
  const navigationTree = getNavigationTree(currentPage, onboardingFlow);

  if (navigationTree) {
    const page = navigationTree[currentPage];

    if (page) {
      const { isSubmitted } = props;
      if (selectedFlows.length > 1 && onboardingFlow) {
        const nextFlow = selectedFlows[1];
        const nextFlowIsLastFlow = selectedFlows.length === 2;
        const isDependantOnCapitalMarkets = getIsDependantOnCapitalMarkets(onboardingFlow);
        const willSkipNextFlow =
          isDependantOnCapitalMarkets && nextFlow === OnboardingType.CAPITAL_MARKETS && nextFlowIsLastFlow;

        if (EndPages.includes(currentPage) && isSubmitted && !willSkipNextFlow) {
          return `/onboarding-redirect`;
        }
      }

      if (page.nextPage) {
        return `/${page.nextPage}`;
      }
      if (page.getNextPage) {
        return `/${page.getNextPage(props)}`;
      }
    }
  }
  return null;
};

export const getRouterPath = (name: string) => {
  return `/${name}`;
};

export const getStepForPage = (currentPage: string, onboardingFlow?: OnboardingType) => {
  const navigationTree = getNavigationTree(currentPage, onboardingFlow);
  if (navigationTree) {
    const page = navigationTree[currentPage];
    if (page) {
      return page.step;
    }
  }
  return undefined;
};

export const getOnboardingSteps = (currentOnboarding?: OnboardingType) => {
  if (currentOnboarding === OnboardingType.GENERAL_BANKING) {
    return GeneralBankingProgressSteps;
  }
  if (currentOnboarding === OnboardingType.CAPITAL_MARKETS) {
    return CapitalMarketsProgressSteps;
  }
  if (currentOnboarding === OnboardingType.PRIVATE_BANKING) {
    return PrivateBankingProgressSteps;
  }
  if (currentOnboarding === OnboardingType.DERIVATIVES) {
    return DerivativesProgressSteps;
  }
  if (currentOnboarding === OnboardingType.KARDIO) {
    return KardioProgressSteps;
  }
  return [];
};
