import { InvestorTypeEnum } from '@kvika/api-types';
import { NavigationConstants } from './NavigationConstants';
import { StepConstants } from './ProgressStepsConstants';
import { NavigationTree, NavigationProps } from '../types/Types';

export type CapitalMarketsNavigationProps = {
  actingRetail?: boolean | null;
  investorType?: InvestorTypeEnum | null;
  isCompany?: boolean | null;
  isComplete?: boolean | null;
  isConfirmationBlocked?: boolean | null;
  isPepBlocked?: boolean | null;
  isRiskBlocked?: boolean | null;
  isBeneficialOwnersBlocked?: boolean | null;
  isSubmitted?: boolean | null;
  numberOfCompanies?: number | null;
  isPepDocumentSigned?: boolean | null;
  hasLEICode?: boolean | null;
  // We need to route a specific thank you page when you have only capital markets selected
  isSingleFlowSelected?: boolean;
};

const selectThankYouPage = (isSingleFlowSelected?: boolean) => {
  if (isSingleFlowSelected) {
    return NavigationConstants.TAKK_FYRIR;
  }
  return NavigationConstants.TAKK_FYRIR_UMSOKN;
};
const selectOnboardingSingleEntityRouting = ({
  isRiskBlocked,
  isPepBlocked,
  isSubmitted,
  isComplete,
  isBeneficialOwnersBlocked,
  isPinSet,
  isSingleFlowSelected,
}: NavigationProps): string => {
  if ((isRiskBlocked || isPepBlocked) && isSubmitted) {
    return NavigationConstants.UMSOKN_PEP;
  }
  if (isBeneficialOwnersBlocked && isSubmitted) {
    return NavigationConstants.SKRANING_MISRAEMI;
  }
  if (!isSubmitted && !isComplete) {
    return NavigationConstants.UPPLYSINGAR;
  }
  if (isSubmitted && !isPinSet && !isComplete) {
    return NavigationConstants.VELDU_PIN;
  }
  return selectThankYouPage(isSingleFlowSelected);
};

export const CapitalMarketsNavigationTree: NavigationTree = {
  [NavigationConstants.VELDU_VORU]: {
    getNextPage: ({
      isCompany,
      isConfirmationBlocked,
      numberOfCompanies,
      isBeneficialOwnersBlocked,
      isSubmitted,
      ...rest
    }) => {
      if (isCompany) {
        if (numberOfCompanies === 0) {
          return NavigationConstants.EKKERT_FELAG;
        }
        if (isBeneficialOwnersBlocked && isSubmitted) {
          return NavigationConstants.SKRANING_MISRAEMI;
        }
        if (isConfirmationBlocked && isSubmitted) {
          return NavigationConstants.UMSOKN_STADFESTING;
        }
      }
      return selectOnboardingSingleEntityRouting({ ...rest, isSubmitted });
    },
  },
  [NavigationConstants.UPPLYSINGAR]: {
    getNextPage: ({ isCompany, hasLEICode }) => {
      if (isCompany && !hasLEICode) {
        return NavigationConstants.LEI_KODI_FANNST_EKKI;
      }
      return NavigationConstants.AREIDANLEIKAKONNUN;
    },
    step: StepConstants.UPPLYSINGAR,
  },
  [NavigationConstants.LEI_KODI_FANNST_EKKI]: {
    nextPage: NavigationConstants.AREIDANLEIKAKONNUN,
    step: StepConstants.UPPLYSINGAR,
  },
  [NavigationConstants.AREIDANLEIKAKONNUN]: {
    nextPage: NavigationConstants.TILHLYDILEIKAMAT,
    step: StepConstants.KANNANIR,
  },
  [NavigationConstants.TILHLYDILEIKAMAT]: {
    getNextPage: ({ isCompany }) => {
      if (isCompany) {
        return NavigationConstants.FLOKKUN_LOGADILI;
      }
      return NavigationConstants.FLOKKUN;
    },
    step: StepConstants.KANNANIR,
  },
  [NavigationConstants.FLOKKUN]: {
    getNextPage: ({ investorType }) => {
      if (investorType === InvestorTypeEnum.PROFESSIONAL) {
        return NavigationConstants.FLOKKUN_FAGFJARFESTIR;
      }
      return NavigationConstants.UNDIRRITUN;
    },
    step: StepConstants.KANNANIR,
  },
  [NavigationConstants.FLOKKUN_FAGFJARFESTIR]: {
    nextPage: NavigationConstants.UNDIRRITUN,
    step: StepConstants.KANNANIR,
  },
  [NavigationConstants.FLOKKUN_LOGADILI]: {
    getNextPage: ({ investorType }) => {
      if (investorType === InvestorTypeEnum.PROFESSIONAL || investorType === InvestorTypeEnum.ELIGIBLE_COUNTERPARTY) {
        return NavigationConstants.FLOKKUN_FAGFJARFESTIR_LOGADILI;
      }
      if (investorType === InvestorTypeEnum.RETAIL) {
        return NavigationConstants.UNDIRRITUN_LOGADILI;
      }
      return NavigationConstants.FANN_EKKI;
    },
    step: StepConstants.KANNANIR,
  },
  [NavigationConstants.FLOKKUN_FAGFJARFESTIR_LOGADILI]: {
    nextPage: NavigationConstants.UNDIRRITUN_LOGADILI,
    step: StepConstants.KANNANIR,
  },
  [NavigationConstants.UNDIRRITUN_PEP]: {
    nextPage: NavigationConstants.UMSOKN_PEP,
    step: StepConstants.SKILMALAR,
  },
  [NavigationConstants.UNDIRRITUN]: {
    getNextPage: ({
      isPepBlocked,
      isRiskBlocked,
      isPepDocumentSigned,
      isCompany,
      isPinSet,
      isComplete,
      isSingleFlowSelected,
    }) => {
      if (isPepBlocked && !isPepDocumentSigned && !isCompany) {
        return NavigationConstants.UNDIRRITUN_PEP;
      }
      if (isRiskBlocked || isPepBlocked) {
        return NavigationConstants.UMSOKN_PEP;
      }

      if (!isPinSet && !isComplete) {
        return NavigationConstants.VELDU_PIN;
      }
      return selectThankYouPage(isSingleFlowSelected);
    },
    step: StepConstants.SKILMALAR,
  },
  [NavigationConstants.UNDIRRITUN_LOGADILI]: {
    getNextPage: ({
      isRiskBlocked,
      isPepBlocked,
      isBeneficialOwnersBlocked,
      isConfirmationBlocked,
      isPinSet,
      isComplete,
      isSingleFlowSelected,
    }) => {
      if (isRiskBlocked || isPepBlocked) {
        return NavigationConstants.UMSOKN_PEP;
      }
      if (isBeneficialOwnersBlocked) {
        return NavigationConstants.SKRANING_MISRAEMI;
      }
      if (isConfirmationBlocked) {
        return NavigationConstants.UMSOKN_STADFESTING;
      }

      if (!isPinSet && !isComplete) {
        return NavigationConstants.VELDU_PIN;
      }
      return selectThankYouPage(isSingleFlowSelected);
    },
    step: StepConstants.SKILMALAR,
  },
  [NavigationConstants.VELDU_PIN]: {
    getNextPage: ({ isSingleFlowSelected }) => {
      return selectThankYouPage(isSingleFlowSelected);
    },
  },
};
