import { Colors, Grid } from '@kvika/theme';
import styled from 'styled-components';

export const StyledSection = styled.section`
  color: ${Colors.gold600};
  margin-top: 20px;
  margin-bottom: ${Grid['16px']};
`;

export const StyledCaption = styled.p`
  border-left: 4px solid ${Colors.gold600};
  padding-left: 20px;
  margin-bottom: ${Grid['16px']};
`;

export const StyledDescription = styled.div`
  display: flex;
  flex-direction: column;
  display: inherit;
  line-height: ${Grid['24px']};
  letter-spacing: 0.5px;
  font-size: ${Grid['16px']};
  white-space: pre-wrap;
  text-align: left;
  white-space: pre-wrap;
`;

export const StyledLink = styled.a`
  position: relative;
  bottom: 24px;
`;
