import * as React from 'react';
import { StyledH3, StyledContentWrapper } from './LayoutTextHeaderStyles';
import { StyledDescription } from '../CommonStyles';

type Props = {
  title?: string | null;
  description?: string | React.ReactNode;
};

const LayoutTextHeader = ({ title, description }: Props) => {
  return (
    <StyledContentWrapper>
      <StyledH3>{title}</StyledH3>
      <StyledDescription>{description}</StyledDescription>
    </StyledContentWrapper>
  );
};

export default LayoutTextHeader;
