import { Banner } from '@kvika/header';
import styled from 'styled-components';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { Colors } from '@kvika/theme';
import { prettifySSN } from '@kvika/string-utils';
import { StyledButton } from './buttons/ButtonStyles';
import { selectEntityState } from '../../store/entity';
import { Banner as BannerTypes } from '../../types/Types';

type Props = {
  banner: Omit<BannerTypes, '_linkType' | '_meta'>;
  onClick?(): void;
};

const StyledButtonText = styled.span`
  text-decoration: underline;
  font-weight: bold;
  font-size: 13px;
  color: ${Colors.gold600};
`;

const StyledTextContainer = styled.span`
  margin-right: 8px;
`;

const BannerWrapper = ({ banner, onClick }: Props): JSX.Element => {
  const entity = useSelector(selectEntityState);
  const userInfo = {
    ssn: entity.ssn,
    isLivingAbroad: entity.address.isLivingAbroad,
    name: entity.name,
  };
  const companyInfo = entity.isCompany
    ? {
        companySsn: entity.ssn,
        companyName: entity.name,
      }
    : null;
  const getText = () => {
    if (companyInfo) {
      return `${banner.on_behalf} ${companyInfo.companyName} (${prettifySSN(companyInfo.companySsn || '')}) `;
    }
    return `${banner.logged_in_as} ${userInfo?.name} (${prettifySSN(userInfo?.ssn || '')}) `;
  };

  return (
    <Banner>
      <div>
        <StyledTextContainer>{getText()}</StyledTextContainer>
        {onClick && entity.isCompany && (
          <StyledButton onClick={onClick}>
            <StyledButtonText>{banner.switch}</StyledButtonText>
          </StyledButton>
        )}
      </div>
    </Banner>
  );
};

export default BannerWrapper;
