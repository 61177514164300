import { OnboardingType } from '@kvika/api-types';
import { NavigationConstants } from './NavigationConstants';

export const ALLOWED_ROUTES = ['/', '/kardio', '/404'];

export const FLOW_PROTECTED_ROUTES = {
  [OnboardingType.CAPITAL_MARKETS]: [
    `/${NavigationConstants.VELDU_VORU}`,
    `/${NavigationConstants.EKKERT_FELAG}`,
    `/${NavigationConstants.SKRANING_MISRAEMI}`,
    `/${NavigationConstants.UMSOKN_STADFESTING}`,
    `/${NavigationConstants.LEI_KODI_FANNST_EKKI}`,
    `/${NavigationConstants.AREIDANLEIKAKONNUN}`,
    `/${NavigationConstants.UPPLYSINGAR}`,
    `/${NavigationConstants.TILHLYDILEIKAMAT}`,
    `/${NavigationConstants.FLOKKUN_LOGADILI}`,
    `/${NavigationConstants.FLOKKUN}`,
    `/${NavigationConstants.FLOKKUN_FAGFJARFESTIR}`,
    `/${NavigationConstants.UNDIRRITUN}`,
    `/${NavigationConstants.FLOKKUN_FAGFJARFESTIR_LOGADILI}`,
    `/${NavigationConstants.UNDIRRITUN_LOGADILI}`,
    `/${NavigationConstants.FANN_EKKI}`,
    `/${NavigationConstants.UNDIRRITUN_PEP}`,
    `/${NavigationConstants.UMSOKN_PEP}`,
    `/${NavigationConstants.VELDU_PIN}`,
  ],
  [OnboardingType.DERIVATIVES]: [
    `/${NavigationConstants.UPPLYSINGAR}`,
    `/${NavigationConstants.LEI_KODI_FANNST_EKKI}`,
    `/${NavigationConstants.AREIDANLEIKAKONNUN}`,
    `/${NavigationConstants.TILHLYDILEIKAMAT}`,
    `/${NavigationConstants.FLOKKUN_LOGADILI}`,
    `/${NavigationConstants.FLOKKUN}`,
    `/${NavigationConstants.FLOKKUN_FAGFJARFESTIR}`,
    `/${NavigationConstants.AFLEIDU_UPPLYSINGAR}`,
    `/${NavigationConstants.UNDIRRITUN}`,
    `/${NavigationConstants.FLOKKUN_FAGFJARFESTIR_LOGADILI}`,
    `/${NavigationConstants.UNDIRRITUN_LOGADILI}`,
    `/${NavigationConstants.FANN_EKKI}`,
    `/${NavigationConstants.FYRIRKOMULAG_UPPGJORS_LOGADILI}`,
    `/${NavigationConstants.FYRIRKOMULAG_UPPGJORS}`,
    `/${NavigationConstants.UNDIRRITUN_AFLEIDUR}`,
    `/${NavigationConstants.UNDIRRITUN_AFLEIDUR_LOGADILI}`,
    `/${NavigationConstants.UNDIRRITUN_PEP}`,
    `/${NavigationConstants.UMSOKN_PEP}`,
    `/${NavigationConstants.VELDU_PIN}`,
    `/${NavigationConstants.TAKK_FYRIR_UMSOKN}`,
    `/${NavigationConstants.SKRANING_MISRAEMI}`,
    `/${NavigationConstants.UMSOKN_STADFESTING}`,
    `/${NavigationConstants.VELDU_VORU}`,
  ],
  [OnboardingType.PRIVATE_BANKING]: [
    `/${NavigationConstants.VELDU_VORU}`,
    `/${NavigationConstants.EKKERT_FELAG}`,
    `/${NavigationConstants.SKRANING_MISRAEMI}`,
    `/${NavigationConstants.UMSOKN_STADFESTING}`,
    `/${NavigationConstants.LEI_KODI_FANNST_EKKI}`,
    `/${NavigationConstants.AREIDANLEIKAKONNUN}`,
    `/${NavigationConstants.UPPLYSINGAR}`,
    `/${NavigationConstants.HAEFISMAT}`,
    `/${NavigationConstants.FLOKKUN_LOGADILI}`,
    `/${NavigationConstants.FLOKKUN}`,
    `/${NavigationConstants.FLOKKUN_FAGFJARFESTIR}`,
    `/${NavigationConstants.UNDIRRITUN}`,
    `/${NavigationConstants.FLOKKUN_FAGFJARFESTIR_LOGADILI}`,
    `/${NavigationConstants.UNDIRRITUN_LOGADILI}`,
    `/${NavigationConstants.FANN_EKKI}`,
    `/${NavigationConstants.UNDIRRITUN_PEP}`,
    `/${NavigationConstants.UMSOKN_PEP}`,
    `/${NavigationConstants.VELDU_PIN}`,
    `/${NavigationConstants.TAKK_FYRIR_UMSOKN}`,
  ],
  [OnboardingType.GENERAL_BANKING]: [
    `/${NavigationConstants.UPPLYSINGAR}`,
    `/${NavigationConstants.LEI_KODI_FANNST_EKKI}`,
    `/${NavigationConstants.AREIDANLEIKAKONNUN}`,
    `/${NavigationConstants.STOFNA_REIKNING}`,
    `/${NavigationConstants.THJONUSTUVAL}`,
    `/${NavigationConstants.UNDIRRITUN_GB_LOGADILI}`,
    `/${NavigationConstants.UNDIRRITUN_GB}`,
    `/${NavigationConstants.UNDIRRITUN_PEP}`,
    `/${NavigationConstants.UMSOKN_PEP}`,
    `/${NavigationConstants.TAKK_FYRIR_UMSOKN}`,
    `/${NavigationConstants.SKRANING_MISRAEMI}`,
    `/${NavigationConstants.UMSOKN_STADFESTING}`,
    `/${NavigationConstants.VELDU_PIN}`,
    `/${NavigationConstants.VELDU_VORU}`,
  ],
  [OnboardingType.KARDIO]: [
    `/${NavigationConstants.FYRIRTAEKI}`,
    `/${NavigationConstants.UPPLYSINGAR}`,
    `/${NavigationConstants.AREIDANLEIKAKONNUN}`,
    `/${NavigationConstants.HEIMILD}`,
    `/${NavigationConstants.UNDIRRITUN_KARDIO}`,
    `/${NavigationConstants.UMSOKN_KARDIO}`,
  ],
};
