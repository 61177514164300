import { NavigationConstants } from './NavigationConstants';
import { NavigationTree } from '../types/Types';

export const CommonNavigationTree: NavigationTree = {
  [NavigationConstants.INNSKRANING]: {
    getNextPage: ({ isCompany, numberOfCompanies }) => {
      if (isCompany) {
        if (numberOfCompanies === 0) {
          return NavigationConstants.EKKERT_FELAG;
        }
        if (numberOfCompanies !== undefined && numberOfCompanies !== null && numberOfCompanies > 1) {
          return NavigationConstants.FYRIRTAEKI;
        }
      }
      return NavigationConstants.VELDU_VORU;
    },
  },
  [NavigationConstants.FYRIRTAEKI]: {
    nextPage: NavigationConstants.VELDU_VORU,
  },
};
