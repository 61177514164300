export const StepConstants = {
  UPPLYSINGAR: 'Upplýsingar',
  KANNANIR: 'Kannanir',
  KONNUN: 'Könnun',
  SKILMALAR: 'Skilmálar',
  THJONUSTUVAL: 'Þjónustuval',
  YFIRLYSING: 'Yfirlýsing',
  UNDIRRITUN: 'Undirritun',
  HEIMILD: 'Heimild',
};

export const GeneralBankingProgressSteps = [
  {
    id: 1,
    label: StepConstants.UPPLYSINGAR,
  },
  {
    id: 2,
    label: StepConstants.KONNUN,
  },
  {
    id: 3,
    label: StepConstants.THJONUSTUVAL,
  },
];

export const CapitalMarketsProgressSteps = [
  {
    id: 1,
    label: StepConstants.UPPLYSINGAR,
  },
  {
    id: 2,
    label: StepConstants.KANNANIR,
  },
  {
    id: 3,
    label: StepConstants.SKILMALAR,
  },
];

export const PrivateBankingProgressSteps = [
  {
    id: 1,
    label: StepConstants.UPPLYSINGAR,
  },
  {
    id: 2,
    label: StepConstants.KANNANIR,
  },
  {
    id: 3,
    label: StepConstants.SKILMALAR,
  },
];

export const DerivativesProgressSteps = [
  {
    id: 1,
    label: StepConstants.UPPLYSINGAR,
  },
  {
    id: 2,
    label: StepConstants.UNDIRRITUN,
  },
];

export const KardioProgressSteps = [
  {
    id: 1,
    label: StepConstants.UPPLYSINGAR,
  },
  {
    id: 2,
    label: StepConstants.KANNANIR,
  },
  {
    id: 3,
    label: StepConstants.HEIMILD,
  },
  {
    id: 4,
    label: StepConstants.SKILMALAR,
  },
];
