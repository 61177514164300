import { InvestorTypeEnum } from '@kvika/api-types';
import { NavigationConstants } from './NavigationConstants';
import { StepConstants } from './ProgressStepsConstants';
import { NavigationProps, NavigationTree } from '../types/Types';

export type DerivativesNavigationProps = {
  isCompany?: boolean | null;
  isComplete?: boolean | null;
  isConfirmationBlocked?: boolean | null;
  isPepBlocked?: boolean | null;
  isRiskBlocked?: boolean | null;
  isSubmitted?: boolean | null;
  isBeneficialOwnersBlocked?: boolean | null;
  isApprovalBlocked?: boolean | null;
  numberOfCompanies?: number | null;
  isPepDocumentSigned?: boolean | null;
  hasFinishedCapitalMarkets?: boolean | null;
};

const loginCheckForSingleEntityRouting = ({
  isRiskBlocked,
  isPepBlocked,
  isSubmitted,
  isComplete,
  isCompany,
  isConfirmationBlocked,
  isBeneficialOwnersBlocked,
  isPinSet,
}: NavigationProps): string => {
  if (isCompany) {
    if (isConfirmationBlocked && isSubmitted) {
      return NavigationConstants.UMSOKN_STADFESTING;
    }

    if (isBeneficialOwnersBlocked && isSubmitted) {
      return NavigationConstants.SKRANING_MISRAEMI;
    }
  }
  if ((isRiskBlocked || isPepBlocked) && isSubmitted) {
    return NavigationConstants.UMSOKN_PEP;
  }

  if (!isSubmitted && !isComplete) {
    return NavigationConstants.UPPLYSINGAR;
  }

  if (isSubmitted && !isPinSet && !isComplete) {
    return NavigationConstants.VELDU_PIN;
  }

  if (isSubmitted && !isPinSet && !isComplete) {
    return NavigationConstants.VELDU_PIN;
  }

  return NavigationConstants.TAKK_FYRIR_UMSOKN;
};

export const DerivativesNavigationTree: NavigationTree = {
  [NavigationConstants.UPPLYSINGAR]: {
    getNextPage: ({ isCompany, hasLEICode }) => {
      if (isCompany && !hasLEICode) {
        return NavigationConstants.LEI_KODI_FANNST_EKKI;
      }
      return NavigationConstants.AREIDANLEIKAKONNUN;
    },
    step: StepConstants.UPPLYSINGAR,
  },
  [NavigationConstants.LEI_KODI_FANNST_EKKI]: {
    nextPage: NavigationConstants.AREIDANLEIKAKONNUN,
    step: StepConstants.UPPLYSINGAR,
  },
  [NavigationConstants.AREIDANLEIKAKONNUN]: {
    nextPage: NavigationConstants.TILHLYDILEIKAMAT,
    step: StepConstants.KANNANIR,
  },
  [NavigationConstants.TILHLYDILEIKAMAT]: {
    getNextPage: ({ isCompany }) => {
      if (isCompany) {
        return NavigationConstants.FLOKKUN_LOGADILI;
      }
      return NavigationConstants.FLOKKUN;
    },
    step: StepConstants.KANNANIR,
  },
  [NavigationConstants.FLOKKUN]: {
    getNextPage: ({ investorType, hasFinishedCapitalMarkets }) => {
      if (investorType === InvestorTypeEnum.PROFESSIONAL) {
        return NavigationConstants.FLOKKUN_FAGFJARFESTIR;
      }
      if (hasFinishedCapitalMarkets) {
        // Skip capital markets document signing since the user has already signed it
        return NavigationConstants.AFLEIDU_UPPLYSINGAR;
      }
      return NavigationConstants.UNDIRRITUN;
    },
    step: StepConstants.KANNANIR,
  },
  [NavigationConstants.FLOKKUN_FAGFJARFESTIR]: {
    nextPage: NavigationConstants.UNDIRRITUN,
    step: StepConstants.KANNANIR,
  },
  [NavigationConstants.FLOKKUN_LOGADILI]: {
    getNextPage: ({ investorType, hasFinishedCapitalMarkets }) => {
      if (investorType === InvestorTypeEnum.PROFESSIONAL || investorType === InvestorTypeEnum.ELIGIBLE_COUNTERPARTY) {
        return NavigationConstants.FLOKKUN_FAGFJARFESTIR_LOGADILI;
      }
      if (hasFinishedCapitalMarkets) {
        // Skip capital markets document signing since the user has already signed it
        return NavigationConstants.AFLEIDU_UPPLYSINGAR;
      }

      if (investorType === InvestorTypeEnum.RETAIL) {
        return NavigationConstants.UNDIRRITUN_LOGADILI;
      }
      return NavigationConstants.FANN_EKKI;
    },
    step: StepConstants.KANNANIR,
  },
  [NavigationConstants.FLOKKUN_FAGFJARFESTIR_LOGADILI]: {
    getNextPage: ({ hasFinishedCapitalMarkets }) => {
      if (hasFinishedCapitalMarkets) {
        // Skip capital markets document signing since the user has already signed it
        return NavigationConstants.AFLEIDU_UPPLYSINGAR;
      }
      return NavigationConstants.UNDIRRITUN_LOGADILI;
    },
    step: StepConstants.KANNANIR,
  },
  [NavigationConstants.UNDIRRITUN]: {
    nextPage: NavigationConstants.AFLEIDU_UPPLYSINGAR,
    step: StepConstants.SKILMALAR,
  },
  [NavigationConstants.UNDIRRITUN_LOGADILI]: {
    nextPage: NavigationConstants.AFLEIDU_UPPLYSINGAR,
    step: StepConstants.SKILMALAR,
  },
  [NavigationConstants.AFLEIDU_UPPLYSINGAR]: {
    step: StepConstants.UPPLYSINGAR,
    getNextPage: ({ isCompany }) => {
      if (isCompany) {
        return NavigationConstants.FYRIRKOMULAG_UPPGJORS_LOGADILI;
      }
      return NavigationConstants.FYRIRKOMULAG_UPPGJORS;
    },
  },
  [NavigationConstants.FYRIRKOMULAG_UPPGJORS]: {
    step: StepConstants.YFIRLYSING,
    nextPage: NavigationConstants.UNDIRRITUN_AFLEIDUR,
  },
  [NavigationConstants.FYRIRKOMULAG_UPPGJORS_LOGADILI]: {
    step: StepConstants.YFIRLYSING,
    nextPage: NavigationConstants.UNDIRRITUN_AFLEIDUR_LOGADILI,
  },
  [NavigationConstants.UNDIRRITUN_AFLEIDUR]: {
    step: StepConstants.UNDIRRITUN,
    getNextPage: ({ isRiskBlocked, isPepDocumentSigned, isPepBlocked, isPinSet, isComplete, isApprovalBlocked }) => {
      if (isPepBlocked && !isPepDocumentSigned) {
        return NavigationConstants.UNDIRRITUN_PEP;
      }
      if (isRiskBlocked || isPepBlocked) {
        return NavigationConstants.UMSOKN_PEP;
      }

      if (!isPinSet && !isComplete && !isApprovalBlocked) {
        return NavigationConstants.VELDU_PIN;
      }
      return NavigationConstants.TAKK_FYRIR_UMSOKN;
    },
  },
  [NavigationConstants.UNDIRRITUN_AFLEIDUR_LOGADILI]: {
    step: StepConstants.UNDIRRITUN,
    getNextPage: ({
      isRiskBlocked,
      isPepBlocked,
      isBeneficialOwnersBlocked,
      isConfirmationBlocked,
      isPinSet,
      isComplete,
      isApprovalBlocked,
    }) => {
      if (isRiskBlocked || isPepBlocked) {
        return NavigationConstants.UMSOKN_PEP;
      }
      if (isBeneficialOwnersBlocked) {
        return NavigationConstants.SKRANING_MISRAEMI;
      }
      if (isConfirmationBlocked) {
        return NavigationConstants.UMSOKN_STADFESTING;
      }

      if (!isPinSet && !isComplete && !isApprovalBlocked) {
        return NavigationConstants.VELDU_PIN;
      }
      return NavigationConstants.TAKK_FYRIR_UMSOKN;
    },
  },
  [NavigationConstants.UNDIRRITUN_PEP]: {
    step: StepConstants.UNDIRRITUN,
    nextPage: NavigationConstants.UMSOKN_PEP,
  },
  [NavigationConstants.VELDU_VORU]: {
    getNextPage: ({ ...rest }) => {
      return loginCheckForSingleEntityRouting(rest);
    },
  },
  [NavigationConstants.VELDU_PIN]: {
    nextPage: NavigationConstants.TAKK_FYRIR_UMSOKN,
  },
};
