import { Logout } from '@kvika/icons';
import * as React from 'react';
import styled from 'styled-components';

import { StyledButton } from './ButtonStyles';

type Props = {
  text: string;
  onClick(): void;
};

const LogoutButton = ({ text, onClick }: Props): JSX.Element => {
  return (
    <Wrapper>
      <StyledButton onClick={onClick} style={{ minWidth: '80px' }}>
        {text} <Logout />
      </StyledButton>
    </Wrapper>
  );
};

export default LogoutButton;

const Wrapper = styled.div`
  margin-right: 0px !important;
`;
