export const NavigationConstants = {
  AREIDANLEIKAKONNUN: 'areidanleikakonnun',
  EKKERT_FELAG: 'ekkert-tengt-felag',
  FANN_EKKI: 'sida-fannst-ekki',
  FYRIRTAEKI: 'fyrirtaeki',
  INNSKRANING: '',
  UMSOKN_PEP: 'umsokn-pep',
  UMSOKN_STADFESTING: 'umsokn-stadfest',
  UNDIRRITUN_PEP: 'undirritun-pep',
  UNDIRRITUN_GB: 'undirritun-gb',
  UNDIRRITUN_GB_LOGADILI: 'undirritun-gb-logadili',
  THJONUSTUVAL: 'thjonustuval',
  STOFNA_REIKNING: 'stofna-reikning',
  UPPLYSINGAR: 'upplysingar',
  VELDU_PIN: 'veldu-pin',
  VELDU_VORU: 'veldu-voru',
  FLOKKUN_FAGFJARFESTIR_LOGADILI: 'flokkun-fagfjarfestir-logadili',
  FLOKKUN_FAGFJARFESTIR: 'flokkun-fagfjarfestir',
  FLOKKUN_LOGADILI: 'flokkun-logadili',
  FLOKKUN: 'flokkun',
  TILHLYDILEIKAMAT: 'tilhlydileikamat',
  HAEFISMAT: 'haefismat',
  UMSOKN_MOTTEKIN: 'umsokn-mottekin',
  UMSOKN_KARDIO: 'umsokn-mottekin-kardio',
  UNDIRRITUN_LOGADILI: 'undirritun-logadili',
  UNDIRRITUN: 'undirritun',
  UNDIRRITUN_KARDIO: 'undirritun-kardio',
  VELJA_SJOD: 'velja-sjod',
  SKRANING_MISRAEMI: 'skraning-misraemi',
  TAKK_FYRIR: 'takk-fyrir',
  AFLEIDU_UPPLYSINGAR: 'afleidu-upplysingar',
  LEI_KODI_FANNST_EKKI: 'lei-kodi-fannst-ekki',
  AFLEIDU_YFIRLYSING: 'afleidu-yfirlysing',
  AFLEIDU_YFIRLYSING_LOGADILI: 'afleidu-yfirlysing-logadili',
  FYRIRKOMULAG_UPPGJORS: 'fyrirkomulag-uppgjors',
  FYRIRKOMULAG_UPPGJORS_LOGADILI: 'fyrirkomulag-uppgjors-logadili',
  UNDIRRITUN_AFLEIDUR: 'undirritun-afleidur',
  UNDIRRITUN_AFLEIDUR_LOGADILI: 'undirritun-afleidur-logadili',
  TAKK_FYRIR_UMSOKN: 'takk-fyrir-umsokn',
  UNDIR_18: 'static/undir-18',
  KARDIO: 'kardio',
  HEIMILD: 'heimild',
};
