import * as React from 'react';
import Image from 'next/image';
import styled from 'styled-components';
import { valueOrDefault } from '../../utils/Utils';

type Props = {
  url: string;
  alt?: string;
  quality?: number;
};

// This is reccomended by Next.js https://nextjs.org/docs/api-reference/next/image#fill when using the fill property

const LayoutImage = ({ url, alt = '', quality = 75 }: Props) => {
  if (!url) {
    return null;
  }

  return (
    <StyledImageWrapper>
      <Image
        src={valueOrDefault(url, '')}
        quality={quality}
        style={{ objectFit: 'cover', objectPosition: '50% 50%' }}
        alt={alt}
        priority
        fill
      />
    </StyledImageWrapper>
  );
};

const StyledImageWrapper = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
`;

export default LayoutImage;
