import { NavigationConstants } from './NavigationConstants';
import { StepConstants } from './ProgressStepsConstants';
import { NavigationTree } from '../types/Types';

export type GeneralBankingNavigationProps = {
  isCompany?: boolean | null;
  isComplete?: boolean | null;
  isConfirmationBlocked?: boolean | null;
  isPepBlocked?: boolean | null;
  isRiskBlocked?: boolean | null;
  isSubmitted?: boolean | null;
  isBeneficialOwnersBlocked?: boolean | null;
  numberOfCompanies?: number | null;
  isPinSet?: boolean | null;
  isPepDocumentSigned?: boolean | null;
  hasSelectedInvestmentFlow?: boolean | null;
};

const loginCheckForSingleEntityRouting = ({
  isRiskBlocked,
  isPepBlocked,
  isSubmitted,
  isComplete,
  isPinSet,
  isCompany,
  isConfirmationBlocked,
  isBeneficialOwnersBlocked,
}: GeneralBankingNavigationProps): string => {
  if (isCompany) {
    if (isConfirmationBlocked && isSubmitted) {
      return NavigationConstants.UMSOKN_STADFESTING;
    }

    if (isBeneficialOwnersBlocked && isSubmitted) {
      return NavigationConstants.SKRANING_MISRAEMI;
    }
  }
  if ((isRiskBlocked || isPepBlocked) && isSubmitted) {
    return NavigationConstants.UMSOKN_PEP;
  }

  if (!isSubmitted && !isComplete) {
    return NavigationConstants.UPPLYSINGAR;
  }

  if (isSubmitted && !isPinSet) {
    return NavigationConstants.VELDU_PIN;
  }

  return NavigationConstants.TAKK_FYRIR_UMSOKN;
};

export const GeneralBankingNavigationTree: NavigationTree = {
  [NavigationConstants.UPPLYSINGAR]: {
    step: StepConstants.UPPLYSINGAR,
    getNextPage: ({ isCompany, hasLEICode, hasSelectedInvestmentFlow }) => {
      if (isCompany && !hasLEICode && hasSelectedInvestmentFlow) {
        return NavigationConstants.LEI_KODI_FANNST_EKKI;
      }
      return NavigationConstants.AREIDANLEIKAKONNUN;
    },
  },
  [NavigationConstants.LEI_KODI_FANNST_EKKI]: {
    nextPage: NavigationConstants.AREIDANLEIKAKONNUN,
    step: StepConstants.UPPLYSINGAR,
  },
  [NavigationConstants.AREIDANLEIKAKONNUN]: {
    step: StepConstants.KONNUN,
    nextPage: NavigationConstants.STOFNA_REIKNING,
  },
  [NavigationConstants.STOFNA_REIKNING]: {
    step: StepConstants.THJONUSTUVAL,
    nextPage: NavigationConstants.THJONUSTUVAL,
  },
  [NavigationConstants.THJONUSTUVAL]: {
    step: StepConstants.THJONUSTUVAL,
    getNextPage: ({ isCompany }) => {
      if (isCompany) {
        return NavigationConstants.UNDIRRITUN_GB_LOGADILI;
      }
      return NavigationConstants.UNDIRRITUN_GB;
    },
  },
  [NavigationConstants.UNDIRRITUN_GB]: {
    step: StepConstants.THJONUSTUVAL,
    getNextPage: ({ isRiskBlocked, isPepDocumentSigned, isPepBlocked }) => {
      if (isPepBlocked && !isPepDocumentSigned) {
        return NavigationConstants.UNDIRRITUN_PEP;
      }
      if (isRiskBlocked || isPepBlocked) {
        return NavigationConstants.UMSOKN_PEP;
      }
      return NavigationConstants.TAKK_FYRIR_UMSOKN;
    },
  },
  [NavigationConstants.UNDIRRITUN_GB_LOGADILI]: {
    step: StepConstants.THJONUSTUVAL,
    getNextPage: ({ isRiskBlocked, isPepBlocked, isBeneficialOwnersBlocked, isConfirmationBlocked }) => {
      if (isRiskBlocked || isPepBlocked) {
        return NavigationConstants.UMSOKN_PEP;
      }
      if (isBeneficialOwnersBlocked) {
        return NavigationConstants.SKRANING_MISRAEMI;
      }
      if (isConfirmationBlocked) {
        return NavigationConstants.UMSOKN_STADFESTING;
      }
      return NavigationConstants.TAKK_FYRIR_UMSOKN;
    },
  },
  [NavigationConstants.UNDIRRITUN_PEP]: {
    step: StepConstants.SKILMALAR,
    nextPage: NavigationConstants.UMSOKN_PEP,
  },
  [NavigationConstants.VELDU_PIN]: {
    nextPage: NavigationConstants.TAKK_FYRIR_UMSOKN,
  },
  [NavigationConstants.VELDU_VORU]: {
    getNextPage: ({ ...rest }) => {
      return loginCheckForSingleEntityRouting(rest);
    },
  },
};
