import { NavigationConstants } from './NavigationConstants';
import { NavigationTree } from '../types/Types';
import { StepConstants } from './ProgressStepsConstants';

export type KardioNavigationProps = {
  isComplete?: boolean | null;
  isConfirmationBlocked?: boolean | null;
  isPepBlocked?: boolean | null;
  isRiskBlocked?: boolean | null;
  isSubmitted?: boolean | null;
  isBeneficialOwnersBlocked?: boolean | null;
  numberOfCompanies?: number | null;
  isPinSet?: boolean | null;
};

export const KardioNavigationTree: NavigationTree = {
  [NavigationConstants.KARDIO]: {
    nextPage: NavigationConstants.FYRIRTAEKI,
  },
  [NavigationConstants.FYRIRTAEKI]: {
    nextPage: NavigationConstants.UPPLYSINGAR,
  },
  [NavigationConstants.UPPLYSINGAR]: {
    nextPage: NavigationConstants.AREIDANLEIKAKONNUN,
    step: StepConstants.UPPLYSINGAR,
  },
  [NavigationConstants.AREIDANLEIKAKONNUN]: {
    nextPage: NavigationConstants.HEIMILD,
    step: StepConstants.KANNANIR,
  },
  [NavigationConstants.HEIMILD]: {
    nextPage: NavigationConstants.UNDIRRITUN_KARDIO,
    step: StepConstants.HEIMILD,
  },
  [NavigationConstants.UNDIRRITUN_KARDIO]: {
    step: StepConstants.SKILMALAR,
    nextPage: NavigationConstants.UMSOKN_KARDIO,
  },
};
